<div class="side-bar-content">
  <div class="actions">
    <div *ngIf="user?.admin!!" class="action-btn" matRipple (click)="navigate('events')">
      <mat-icon fontIcon="notifications"></mat-icon>
    </div>
    <div *ngIf="user?.admin!!" class="action-btn" matRipple (click)="navigate('settings')">
      <mat-icon fontIcon="settings"></mat-icon>
    </div>
    <div class="action-btn" [matMenuTriggerFor]="menu" matRipple>
      <img [src]="userPicture" alt="Profile pic" referrerpolicy="no-referrer" />
    </div>
  </div>
  <mat-menu #menu="matMenu">
    <ul>
      <li (click)="logOut()" matRipple>
        <mat-icon fontIcon="logout"></mat-icon>
        LogOut
      </li>
    </ul>
  </mat-menu>
  <div class="links">
    <!--    <a-->
    <!--      routerLink="/"-->
    <!--      routerLinkActive="active-link"-->
    <!--      [routerLinkActiveOptions]="{ exact: true }"-->
    <!--      matRipple-->
    <!--      style="pointer-events: none"-->
    <!--    >-->
    <!--      <mat-icon fontIcon="show_chart"></mat-icon>-->
    <!--      Dashboard-->
    <!--    </a>-->
    <a (click)="this.toggleSideBar()" routerLink="/all-invoices" routerLinkActive="active-link" matRipple>
      <mat-icon fontIcon="library_books"></mat-icon>
      Invoices
    </a>
    <a *ngIf="user?.admin!!" (click)="this.toggleSideBar()" routerLink="/clients" routerLinkActive="active-link" matRipple>
      <mat-icon fontIcon="how_to_reg"></mat-icon>
      Clients
    </a>
    <a
      *ngIf="user?.admin!!"
      (click)="this.toggleSideBar()"
      routerLink="/projects"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: false }"
      matRipple
    >
      <mat-icon fontIcon="code"></mat-icon>
      Projects
    </a>
    <a *ngIf="user?.admin!!"
    (click)="this.toggleSideBar()"
    routerLink="/recipients" routerLinkActive="active-link" matRipple>
      <mat-icon fontIcon="recent_actors"></mat-icon>
      Recipients
    </a>
    <a *ngIf="user?.admin!!" (click)="this.toggleSideBar()" routerLink="/products" routerLinkActive="active-link" matRipple>
      <mat-icon fontIcon="pets"></mat-icon>
      Products
    </a>
  </div>
  <div class="landing-message">
    <img src="../../../assets/img/invoice-illustration.svg" alt="Invoices" />
    <p>
      <span>Welcome to Billy!</span><br />
      Generate and manage your invoices!
    </p>
  </div>
</div>
