import { Component, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from './services/loader.service';
import { Router } from '@angular/router';
import { IconService } from './services/icon.service';
import { MatDrawer } from '@angular/material/sidenav';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'billy-frontend';
  @ViewChild('drawer') drawer!: MatDrawer;
  userPicture: string = '../../../assets/img/user-img.webp';
  isDrawerOpened: boolean = false;
  devTitle: string = '';

  constructor(
    public loaderService: LoaderService,
    public router: Router,
    private iconService: IconService,
  ) {
    this.iconService.registerIcons();
  }
  ngOnInit(): void {
    if(!environment.production) this.devTitle = '- Dev'
  }

  toggleMenu() {
    if (this.drawer) {
      this.drawer.toggle();
      this.isDrawerOpened = !this.isDrawerOpened;
    }
  }

  closeDrawerOnOptionSelected() {
    if (this.drawer) {
      this.drawer.close();
      this.isDrawerOpened = true;
    }
  }
}
