import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { lastValueFrom, Observable } from 'rxjs';
import { APIInvoice } from "../common/interfaces/invoice";
import { IAPICurrency } from "../common/interfaces/currency";
import { IAPISprint } from "../common/interfaces/sprint";

export interface APIProject {
  id: string;
  name: string;
  international: boolean;
  logo?: string;
  invoices: APIInvoice[];
  archived: boolean;
  archivedDate: string;
  clientId: string;
  currencyId: string;
  status: string;
  dueDateOffset: number;
  client: {
    createdAt: string;
    id: string;
    name: string;
    updatedAt: string;
  };
  currency: IAPICurrency;
  sprints: IAPISprint[];
}

export interface APISingleProject {
  id: string;
  name: string;
  international: boolean;
  archived: boolean;
  dueDateOffset: number;
  schedule: {
    autoPayOnSend: boolean;
    id: string;
    customEmailTemplate: string;
    autoSend: boolean;
    requiresApproval: boolean;
    sendEmail: string;
    sendAsEmail: string;
    scheduleRecipient: Array<{
      isPrimary: boolean;
      recipientId: string;
      scheduleId: string;
    }>;
  };
  invoices: APIInvoice[];
  currency: IAPICurrency;
}

export interface APIPostProject {
  name: string;
  clientId: string;
  currencyId: string;
  status: string;
  logo?: string;
  dueDateOffset: number;
  international: boolean;
  archived?: boolean;
  archivedDate?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  $sendAutoSendData = new Observable(); // TODO: implement this

  constructor(public http: HttpClient) {}
  getProjects(): Promise<APIProject[]> {
    return lastValueFrom<APIProject[]>(
      this.http.get<APIProject[]>(environment.baseUrl + '/projects')
    );
  }
  getProjectById(projectId: string): Promise<APISingleProject> {
    return lastValueFrom<APISingleProject>(
      this.http.get<APISingleProject>(environment.baseUrl + `/projects/${projectId}/schedule`)
    ); // FIXME: change is  endpoint later
  }

  updateScheduleAutoSend(projectId: string, value: boolean) {
    return lastValueFrom<APISingleProject>(
      this.http.put<APISingleProject>(
        environment.baseUrl + `/projects/${projectId}/schedule/autoSend`,
        { value }
      )
    );
  }

  updateScheduleRequiresApproval(projectId: string, value: boolean) {
    return lastValueFrom<APISingleProject>(
      this.http.put<APISingleProject>(
        environment.baseUrl + `/projects/${projectId}/schedule/requiresApproval`,
        { value }
      )
    );
  }

  updateautoPayOnSend(projectId: string, value: boolean) {
    return lastValueFrom<APISingleProject>(
      this.http.put<APISingleProject>(
        environment.baseUrl + `/projects/${projectId}/schedule/autoPayOnSend`,
        { value }
      )
    );
  }

  postProject(data: APIPostProject) {
    return lastValueFrom(this.http.post<APIPostProject>(environment.baseUrl + '/projects', data));
  }

  putProject(data: APIPostProject, id: string) {
    return lastValueFrom(
      this.http.put<APIPostProject>(`${environment.baseUrl}/projects/${id}`, data)
    );
  }
}
