import { Component, EventEmitter, Output } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { Router } from '@angular/router';
import {IAPIUser} from "../../interfaces/user";
import {UsersService} from "../../../services/users.service";

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent {
  userPicture: string = '../../../assets/img/user-img.webp';
  user?: IAPIUser | null;
  @Output() optionSelected = new EventEmitter<void>();

  constructor(public router: Router,
    private auth: Auth,
    public usersService: UsersService
    ) {}

  ngOnInit(): void {
    authState(this.auth).subscribe(async (authState) => {
      this.user = await this.usersService.me();
      this.userPicture = authState?.photoURL ?? '../../../assets/img/user-img.webp';
    });
  }

  async navigate(page: string) {
    await this.router.navigate([`/${page}`]);
    this.toggleSideBar();
  }

  logOut() {
    void this.auth.signOut();
    void this.router.navigate(['/login']);
  }

  toggleSideBar(){
    if (window.innerWidth < 768) this.optionSelected.emit();
  }
}
