import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, switchMap, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(private auth: Auth) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return idToken(this.auth).pipe(
      take(1),
      switchMap((token: any) => {
        const newReq = req.clone({
          headers: req.headers.set('x-billy-api-auth', token),
        });
        return next.handle(newReq);
      })
    );
  }
}
